var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-button",
        {
          staticClass: "mt-3",
          attrs: { color: "primary", type: "flat" },
          on: {
            click: function($event) {
              _vm.cadastrarFilialPopup = true
            }
          }
        },
        [
          _c("b", { staticClass: "mr-2" }, [_vm._v("Adicionar filial")]),
          _c("i", { staticClass: "onpoint-plus-circle" })
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "cadastrar-filial",
          attrs: { title: "", active: _vm.cadastrarFilialPopup },
          on: {
            "update:active": function($event) {
              _vm.cadastrarFilialPopup = $event
            }
          }
        },
        [
          _c("h3", { staticClass: "pl-4 text-primary mb-8" }, [
            _vm._v("Adicionar nova filial")
          ]),
          _c("div", { staticClass: "p-4 pt-0 mb-8" }, [
            _c(
              "div",
              { staticClass: "vx-col" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "##.###.###/####-##",
                      expression: "'##.###.###/####-##'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: { label: "Digite o CNPJ da filial", size: "large" },
                  model: {
                    value: _vm.cnpj,
                    callback: function($$v) {
                      _vm.cnpj = $$v
                    },
                    expression: "cnpj"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col mt-5 text-right" },
              [
                _c(
                  "vs-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.salvarFilial()
                      }
                    }
                  },
                  [_vm._v("\n          Salvar filial\n        ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }